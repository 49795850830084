<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-group
              label="INPO"
              label-for="classification"
            >
              <b-form-select
                v-model="selectedEntityId"
                :options="entityId"
                class="mb-3"
                value-field="id"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select INPO --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                :to="{name: 'add-prayer-booking'}"
                variant="primary"
              >
                <span class="text-nowrap">Add Prayer Booking</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="my-table"
        ref="table"
        :items="paginatedItems"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="0"
        aria-busy="true"
        class="position-relative"
        responsive
        show-empty
        empty-text="No matching records found, Plese Select An INPO"
        primary-key="id"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #cell(date)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              2021-12-11
            </b-link>
            <small class="text-muted"> 10:10 AM </small>
          </b-media>
        </template>
        <!-- Column: Title -->
        <template #cell(title)="data">
          {{ data.item.activity_name }}
        </template>

        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #cell(sessions)="data">
          {{ data.item.sessions_count }} Sessions
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #cell(registered)="data">
          15
        </template>

        <template #cell(notes)="data">
          {{ data.item.activity_description.slice(0, 50) }}...
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'edit-prayer-booking', params:{ id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteEvent(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <b-row v-if="Array.isArray(paginatedItems)">
      <b-col md="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          first-number
          last-number
          class="mb-0 mt-1"
          prev-class="prev-item"
          next-class="next-item"
          @input="onPageChanged"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BDropdown, BDropdownItem, BPagination, BFormSelect, BFormSelectOption, BFormGroup,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import bookingPrayersModule from '../BookingPrayersModule'

import bookingPrayers from '../BookingPrayers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    BFormSelectOption,
    BFormGroup,
  },
  data() {
    return {
      items: null,
      paginatedItems: [],
      totalRows: null,
      currentPage: 1,
      perPage: 5,
      entity: null,
      entityId: [
        {
          id: 1,
          name: 'MuslimDo',
        },
        {
          id: 2,
          name: 'MuslimDo-Test',
        },
      ],
    }
  },
  watch: {
    entityData(oldval) {
      this.totalRows = oldval.length
      this.entity = oldval
      this.paginatedItems = oldval.slice(0, this.perPage)
      this.items = oldval
    },
  },
  setup() {
    const EVENTS_MODULE = 'eventsModule'
    // Register module
    if (!store.hasModule(EVENTS_MODULE)) store.registerModule(EVENTS_MODULE, bookingPrayersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_MODULE)) store.unregisterModule(EVENTS_MODULE)
    })
    const {
      tableColumns, events, prayers, selectedEntityId, entityData,
    } = bookingPrayers()

    return {
      tableColumns,
      events,
      prayers,
      selectedEntityId,
      entityData,
    }
  },
  mounted() {
    this.paginate(this.perPage, 0)
  },
  methods: {
    // eslint-disable-next-line camelcase
    paginate(page_size, page_number) {
      const itemsToParse = this.items
      this.paginatedItems = itemsToParse.slice(
        // eslint-disable-next-line camelcase
        page_number * page_size,
        // eslint-disable-next-line camelcase
        (page_number + 1) * page_size,
      )
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1)
    },
    deleteEvent(id) {
      axios.delete(`https://activities.muslimdo.com/api/internalops/activities/${id}`).then(res => {
        console.log(res.data)
      })
    },
  },
}
</script>

<style>

</style>
