import axios from 'axios'

export default {
  namespaced: true,
  state: {
    events: [],
    showEventData: [],
    searchedData: [],
    entityId: null,
    sessions: [],
    individualSession: [],
    returnedEventsData: null,
  },
  getters: {},
  mutations: {
    GET_DATA_PRAYERS(state, data) {
      state.events = data
    },
    GET_SHOW_EVENT(state, data) {
      state.showEventData = data
    },
    GET_SEARCH(state, data) {
      state.searchedData = data
    },
    GET_ENTITY_ID(state, data) {
      state.entityId = data
    },
    GET_SHOW_SESSION(state, sessions) {
      state.sessions = sessions
    },
    GET_SHOW_INDIVIDUAL_SESSION(state, data) {
      state.individualSession = data
    },
    GET_RETURNED_EVENTS_DATA(state, data) {
      state.returnedEventsData = data
    },
  },
  actions: {
    getEvents({ commit }) {
      axios.get('https://activities.muslimdo.com/api/internalops/activities/prayer/2').then(res => {
        commit('GET_DATA_PRAYERS', res.data.data.data)
      })
    },
    showEvent({ commit }, { id }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/activities/show/${id}/prayer`).then(res => {
        commit('GET_SHOW_EVENT', res.data.data.activity)
      }).catch(err => console.log(err))
    },
    addEvents({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('https://activities.muslimdo.com/api/internalops/activities', data)
          .then(response => {
            commit('GET_RETURNED_EVENTS_DATA', response.data.data.id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateAnnounce({ ctx }, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://activities.muslimdo.com/api/internalops/activities/${id}?_method=PUT`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    search({ commit }, { searchQuery }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/search/announcement/${searchQuery}`)
        .then(res => {
          commit('GET_SEARCH', res.data.data)
        }).catch(err => console.log(err))
    },
    filterByEntityId({ commit }, { id }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/filter/prayer/entity_id/${id}`).then(res => {
        commit('GET_ENTITY_ID', res.data.data)
      }).catch(err => console.log(err))
    },
    showSessionList({ commit }, { id }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/sessions/list/${id}`).then(res => {
        commit('GET_SHOW_SESSION', res.data.data)
      }).catch(err => console.log(err))
    },
    showIndividualSession({ commit }, { id }) {
      axios.get(`https://activities.muslimdo.com/api/internalops/sessions/${id}`).then(res => {
        commit('GET_SHOW_INDIVIDUAL_SESSION', res.data.data)
      }).catch(err => console.log(err))
    },
    addSession(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('https://activities.muslimdo.com/api/internalops/sessions', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
