/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function bookingPrayers() {
  const toast = useToast()
  const addTableColumns = [
    { key: 'date-time', sortable: true },
    { key: 'duration', sortable: true },
    { key: 'speakers', sortable: true },
    { key: 'capacity', sortable: true },
    { key: 'targetAudience', sortable: true },
    { key: 'notes', sortable: true },
    { key: 'registered', sortable: true },
    { key: 'actions' },
  ]

  const showSession = ref(true)
  const showAddSession = ref(false)

  const tableColumns = [
    { key: 'date', sortable: true },
    { key: 'title', sortable: true },
    { key: 'sessions', sortable: true },
    { key: 'registered', sortable: true },
    { key: 'notes', sortable: true },
    { key: 'actions' },
  ]

  const selectedEntityId = ref(null)

  const showBTN = ref(true)

  const addBooking = ref({
    entity_id: 2,
    activity_name: null,
    activity_description: null,
    start_time: null,
    end_time: null,
    activity_type: 'prayer',
    creator_id: 1,
  })

  const events = [
    {
      id: 1,
      event_date: '11-8-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Jomaa',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 2,
      event_date: '07-9-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Taraweeh',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 3,
      event_date: '11-8-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Jomaa',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 4,
      event_date: '07-9-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Taraweeh',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 5,
      event_date: '11-8-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Jomaa',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 6,
      event_date: '07-9-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Taraweeh',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 7,
      event_date: '11-8-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Jomaa',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 8,
      event_date: '07-9-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Taraweeh',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 9,
      event_date: '11-8-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Jomaa',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
    {
      id: 10,
      event_date: '07-9-2020',
      event_time: '12:00 PM | 12:45 PM',
      title: 'Taraweeh',
      sessions: '5',
      registered: '35',
      note_actions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus molestias dicta, qui eaque voluptate.',
    },
  ]

  const addedData = ref(null)

  const getPrayers = () => store.dispatch('eventsModule/getEvents')
  getPrayers()

  const prayers = computed(() => store.state.eventsModule.events)

  const filteredData = watchEffect(() => store.dispatch('eventsModule/filterByEntityId', { id: selectedEntityId.value }))

  const entityData = computed(() => store.state.eventsModule.entityId)

  const returnedData = ref([])

  const addEventsData = () => {
    store.dispatch('eventsModule/addEvents', addBooking.value).then(res => {
      returnedData.value = res.data.data
      if (res.data.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        showSession.value = true
      // eslint-disable-next-line no-empty
      } else {}
    }).catch()
  }

  const getSessions = () => store.dispatch('eventsModule/showSessionList', { id: router.currentRoute.params.id })
  getSessions()

  const sessionsData = computed(() => store.state.eventsModule.sessions)

  const getShowEvents = () => store.dispatch('eventsModule/showEvent', { id: router.currentRoute.params.id })
  getShowEvents()

  const eventData = computed(() => store.state.eventsModule.showEventData)

  const sessionId = ref(null)
  watchEffect(() => store.dispatch('eventsModule/showIndividualSession', { id: sessionId.value }))

  const individualSessionData = computed(() => store.state.eventsModule.individualSession)

  const activityId = ref(null)

  watch(() => returnedData.value, val => {
    console.log(val.id)
    activityId.value = val.id
  })

  console.log(returnedData)

  const addSession = ref({
    activity_id: activityId,
    session_address: null,
    session_start: null,
    session_end: null,
    total_amount: 5,
    session_target_gender: null,
    session_max_attendance: null,
    session_languages: null,
  })

  const addNewSession = () => {
    showAddSession.value = true
  }

  const creatSession = () => {
    store.dispatch('eventsModule/addSession', addSession.value).then(res => {
      if (res.data.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Session Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        showSession.value = true
      // eslint-disable-next-line no-empty
      } else {}
    }).catch()
  }

  return {
    tableColumns,
    addTableColumns,
    events,
    addSession,
    addBooking,
    prayers,
    selectedEntityId,
    filteredData,
    entityData,
    addEventsData,
    showBTN,
    sessionsData,
    eventData,
    sessionId,
    individualSessionData,
    showSession,
    showAddSession,
    addNewSession,
    addedData,
    returnedData,
    creatSession,
    activityId,
  }
}
